doulaeasy.init_hiring_date_pickers = function() {

	// these elements can be found in home search bar
	var hiring_from_date = $( "#hiring_from_date" );
	var hiring_to_date = $( "#hiring_to_date" );

	// if both elements are found, prepare the elements

	hiring_from_date.datepicker({
      // defaultDate: "+1w",
      changeMonth: true,
      changeYear: true,
      minDate: "0",
      maxDate: '+1Y',
      // numberOfMonths: 3,
      onClose: function( selectedDate ) {
      	if (selectedDate) {
        	$( "#hiring_to_date" ).datepicker( "option", "minDate", selectedDate );
      		// $( "#hiring_to_date" ).datepicker("show"); // has bug when changing to_date year/month
        }
      },
    });
	hiring_to_date.datepicker({
      // defaultDate: "+1w",
      changeMonth: true,
      changeYear: true,
      minDate: "0",
      maxDate: '+1Y',
      // numberOfMonths: 3,
      onClose: function( selectedDate ) {
      	if (selectedDate) {
        	$( "#hiring_from_date" ).datepicker( "option", "maxDate", selectedDate );
        }
      },
    });

    $(function() {
        // set default dates
        if (hiring_from_date.val()) {
            hiring_from_date.datepicker('setDate',new Date( hiring_from_date.val() ) );
        }
        if (hiring_to_date.val()) {
            hiring_to_date.datepicker('setDate',new Date( hiring_to_date.val() ) );
        }
    });


    // restrict date range from today
    // hiring_from_date.datepicker( "option", "minDate", "0" );
    // hiring_to_date.datepicker( "option", "minDate", "0" );

    // restrict date range within two year 
    // hiring_from_date.datepicker( "option", "maxDate", "+1Y" );
    // hiring_to_date.datepicker( "option", "maxDate", "+1Y" );

    // add clear button at date fields
 //    hiring_from_date.clearable();
	// hiring_from_date.next().hide(); // hide the clear button first
 //    hiring_to_date.clearable();
	// hiring_to_date.next().hide(); // hide the clear button first


    hiring_from_date.change(function() { // only fire search when both datepickers are chosen
        if (!$(this).val()){
            // track select from date
            ga('send', 'event',  'home search: select from date', 'removed');
            $( "#hiring_to_date" ).datepicker( "option", "minDate", "0" );
        }
        else {
            // track select from date
            var timeDiff = Math.abs((new Date($(this).val())) - new Date());
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
            ga('send', 'event',  'home search: select from date', 'selected', 'date', diffDays);
        }

        if (($('#hiring_from_date').val() && $('#hiring_to_date').val()) || (!$('#hiring_from_date').val() && !$('#hiring_to_date').val()))
            return true;
        return false;
    });
    
    hiring_to_date.change(function() { // only fire search when both datepickers are chosen
        if (!$(this).val()){
            // track select to date
            ga('send', 'event',  'home search: select to date', 'removed');
            $( "#hiring_from_date" ).datepicker( "option", "maxDate", "+1Y" );
        }
        else {
            // track select from date
            var timeDiff = Math.abs((new Date($(this).val())) - new Date());
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
            ga('send', 'event',  'home search: select to date', 'selected', 'date', diffDays);
        }

        if (($('#hiring_from_date').val() && $('#hiring_to_date').val()) || (!$('#hiring_from_date').val() && !$('#hiring_to_date').val()))
            return true;
        return false;
    });

};