
var search_result_height;
var slidingDuration = 1800;
doulaeasy.original_search_form = null;


var contentSlidedUp = function() {
    return $('#search_result_content').css('top') <= ("-" + search_result_height + 'px');
};
var contentSlidedDown = function() {
    return $('#search_result_content').css('top') == "0px";
};
var contentSliding = function () {
    return !contentSlidedUp() && !contentSlidedDown();
};

var delayed_expand;

var expand_search_result = function(ajaxCall) {

    if (contentSlidedUp()) {
        $('#search_result_content').animate({
            top: 0,
        }, {duration: slidingDuration, easing: "easeOutQuint"});
    }
    else if (!contentSlidedDown() && ajaxCall) {
        collapse_search_result();
        clearTimeout(delayed_expand);
        delayed_expand = setTimeout('expand_search_result(false);', slidingDuration);
    }
};


var collapse_search_result = function() {

    if (!contentSlidedUp()) {
        var search_result_content = $('#search_result_content');
        search_result_content.clearQueue();
        search_result_content.animate({
            // top: '-'+ search_result_height, // fixed collapse not complete problem
            top: '-'+ (search_result_content.height() + 100),
        }, {duration: slidingDuration, easing: "easeOutQuint"});
        $('#boxes').infinitescroll('destroy');
    }

};

doulaeasy.animate_helper_card_flip = function(_this, is_front) {

    // $(this).shape('flip over');
    // $(this).shape('flip back');
    // $(this).toggleClass('hover');

    if (doulaeasy.iOS) {
        // swap front and back with display toggle
        _this.find('.side').toggleClass('display_none');
    }
    else {
        // flip front and back with css
        _this.toggleClass('hover');
    }
}

var animate_helper_card = function(user_name) {

    if (!$.browser.mobile) { // if desktop

        $('#'+user_name).hoverIntent({
            interval: 150,
            over: function(){
                // track desktop flip over helper card
                ga('send', 'event',  'helper card', 'flip over', user_name);

                doulaeasy.animate_helper_card_flip($(this), true);
            },
            timeout: 1000,
            out: function(){
                // track desktop flip over helper card
                ga('send', 'event',  'helper card', 'flip back', user_name);
                
                doulaeasy.animate_helper_card_flip($(this), false);
            },
            selector: '.card-container'
        });

        // show badge popup if hover
        $('#'+user_name).find('.badge-popup').popup({
            distanceAway: 30, // for helper card
            offset: 0
        });

    }

    else { // if mobile

        var helper_card_shape = $('#'+user_name).find('.card-container');

        helper_card_shape.swipe('destroy');
        helper_card_shape.swipe({
            //Generic swipe handler for all directions
            swipeLeft:function(event, direction, distance, duration, fingerCount, fingerData) {
                
                // track swipeLeft helper card
                ga('send', 'event',  'helper card', 'swipe left', user_name);

                doulaeasy.animate_helper_card_flip($(this));
            },
            swipeRight:function(event, direction, distance, duration, fingerCount, fingerData) {

                // track swipeRight helper card
                ga('send', 'event',  'helper card', 'swipe right', user_name);

                doulaeasy.animate_helper_card_flip($(this));
            },
            //Default is 75px, set to 0 for demo so any distance triggers swipe
           threshold: 75
        
        });
        helper_card_shape.click(function() {

                // track swipeRight helper card
                ga('send', 'event',  'helper card', 'click', user_name);

                if ($(this).find('.active.side').hasClass('back')) {
                    doulaeasy.animate_helper_card_flip($(this));
                }
                else {
                    doulaeasy.animate_helper_card_flip($(this));
                }
        });

        // show badge popup if clicked
        $('#'+user_name+' .badge-popup').popup({
            distanceAway: 40, // for helper card
            offset: 0,
            onShow:function() {
                var link = $('.badge-link-icon');
                if (link) link.attr('href', $('#badge_link').attr('href'));
            }
        });

    }

    // make coffee icon link to author posts
    $('#'+user_name).find('.back').find('i.coffee.icon').click(function(event) {
        event.stopPropagation();
        $(this).find('a')[0].click();
    });

    // flip card in advance for non-iOS devices
    if (!doulaeasy.iOS) {
        setTimeout(function() {
            // $('#'+user_name).find('.card-container').shape('flip over');
            $('#'+user_name).find('.card-container').toggleClass('hover');
            setTimeout(function() {
                // $('#'+user_name).find('.card-container').shape('flip back');
                $('#'+user_name).find('.card-container').toggleClass('hover');
            }, 1000 + Math.ceil(Math.random() * 6) * 150);
        }, Math.ceil(Math.random() * 6) * 150);
    }
}

var init_search_result = function(slide) {

    // hide paginate div
    $('#search_result_paginate').hide();
        
    init_helper_collected();
    init_helper_visited();

    var search_result_content = $('#search_result_content');
    search_result_height = search_result_content.height() + 100;

    $('#boxes').infinitescroll({
        loading: {
            finishedMsg: "",
            msgText: "",
            speed: 'fast',
            selector: '#infscr_loading_helpers'
        },
        navSelector     : ".paginate",
        nextSelector    : ".paginate a:last",
        itemSelector    : ".helper-card.card",
        pixelsFromNavToBottom: 50,
        debug           : false,
        dataType        : 'html',
        path: function(index) {

            var form_params = $('#search_bar_form').serializeArray();
            form_params.splice(0, 1); // splice out "page" parameter
            return "?page=" + index + "&" + $.param(form_params);
        }
    }, function(newElements, data, url){

        $('#infscr-loading').remove();

        $(newElements).each(function( index ) {
            init_helper_card($( this ).attr('id'), $( this ).data('id'));
        });

        // determine flip or swap for helper cards
        doulaeasy.determine_flip_or_swap()

        // insert belt ad among helper cards
        if ( (doulaeasy.getParameterByName('page', url) %2) == 0) {
            if (newElements.length > 0)
                $(newElements[0]).before(doulaeasy.beltAdsDiv[0]);

            // belt_ad_shown_count++;
        }
        
        search_result_height = $('#search_result_content').height() + 30;

    });

    // determine flip or swap for helper cards
    doulaeasy.determine_flip_or_swap()

    if (slide) {
        search_result_content.css('top', '-'+search_result_height+'px');
        expand_search_result(true);
    }

};

function toggleDisableForm() {
    $('#select_doula').toggleClass('disabled');
    $('#select_babysitter').toggleClass('disabled');
    $('#btn_living_district').toggleClass('disabled');
    $('#hiring_from_date').toggleClass('disabled');
    $('#hiring_to_date').toggleClass('disabled');
    $('#hourly_salary_negotiable_div').toggleClass('disabled');
    $('#slider-range-job-count').toggleClass('disabled');
    $('#slider-range-hourly-salary').toggleClass('disabled');
    $('#find_babysitter').toggleClass('disabled');
    $('#find_twenty_four_hour').toggleClass('disabled');
    $('.common-languages').toggleClass('disabled');
    $('.other-language').toggleClass('disabled');
}

var previous_submit; // store current ajax submit function
var ajax_call_seq = 0; // store the ajax call sequence, fix asynchronous ajax calls out of order problem
var init_search_bar = function(initialize){

    if (initialize)
        doulaeasy.original_search_form = $('#search_bar_form').clone();

    $('#helper_query').unbind().on('keyup change', function() {
        event.stopPropagation();
        doulaeasy.delay(function() {
            $('#search_bar_form').submit();
        }, 800);
    });

    $('#select_doula').unbind().click(function() {

        // track enable select doula
        ga('send', 'event',  'home search: select doula', 'enable');

        $("#select_doula").addClass('green');
        $('#select_babysitter').removeClass('blue');
        $('#search_role').val('doula');
        $('#search_bar_form').change();
    });
    $('#select_babysitter').unbind().click(function() {
        
        // track enable select babysitter
        ga('send', 'event',  'home search: select babysitter', 'enable');

        $("#select_doula").removeClass('green');
        $('#select_babysitter').addClass('blue');
        $('#search_role').val('babysitter');
        $('#search_bar_form').change();
    });

    $('#btn_district_reset').unbind().click(function() {
        $('.district-selector .master.checkbox,.child.checkbox').checkbox('set unchecked');
    });

    $('#btn_district_confirm').unbind().click(function() {
        
        // track confirm select district
        ga('send', 'event',  'home search: select district', 'confirm');

        $('#search_bar_form input[name="target_districts[]"]').remove();

        var districts_selected = [];
        $('input[name="target_districts[]"]:checked').each(function() {

            // track selected district
            ga('send', 'event',  'home search: select district', 'selected', $(this).siblings('label').html());

            $('<input>').attr({
                type: 'hidden',
                id: this.value,
                name: 'target_districts[]',
                value: this.value
            }).appendTo('form');
            districts_selected.push($(this).siblings('label').html());
        });
        $('#districts_selected').html(districts_selected.join(', '));

        if ($('input[name="target_districts[]"]:checked').length) {
            $('#label_my_living_place').hide();
            $('#btn_living_district').addClass('active');
            $('#btn_living_district').find('i').toggleClass('green');
        }
        else {
            $('#label_my_living_place').show();
            $('#btn_living_district').removeClass('active');  
            $('#btn_living_district').find('i').toggleClass('green'); 
        }

        $('#search_bar_form').submit();

        $('.ui.modal.district.selector').modal('hide');
    });

    $('#btn_living_district').unbind().click(function() {

        // track show select district modal selector
        ga('send', 'event',  'home search: select district', 'show modal selector');

        $('.ui.modal.district.selector')
            .modal('setting',  {
                onHide    : function(){
                    // track close select district modal selector
                    ga('send', 'event',  'home search: select district', 'close modal selector');
                },
                transition: 'scale'
              })
            .modal('show');
        return false;
    });

    $('#find_twenty_four_hour').unbind().click(function() {
        if ($('input[name="find_twenty_four_hour"]').val() == "false") {
            // track enable select twenty four hour
            ga('send', 'event',  'home search: select twenty four hour', 'enable');

            $(this).addClass('purple');
            $('input[name="find_twenty_four_hour"]').val("true");
        }
        else {
            // track disable select twenty four hour
            ga('send', 'event',  'home search: select twenty four hour', 'disable');

            $(this).removeClass('purple');
            $('input[name="find_twenty_four_hour"]').val("false");
        }
        $('#search_bar_form').submit();
    });

    $('#helper_collection').unbind().click(function() {
        
        $(this).toggleClass('red');
        $(this).find('i').toggleClass('red');


        if ($('input[name="helper_collection"]').val() == "") {
            // track enable helper collection
            ga('send', 'event',  'home search: helper collection', 'enable');

            if (doulaeasy.wished_helpers.length > 0) {
                $('input[name="helper_collection"]').val(doulaeasy.wished_helpers.join());
            }
            else {
                $('input[name="helper_collection"]').val("false");
            }
        }
        else {
            // track disable helper collection
            ga('send', 'event',  'home search: helper collection', 'disable');

            $('input[name="helper_collection"]').val("");
        }

        // toggle disable all other inputs if helper collection button is clicked
        toggleDisableForm();

        $('#search_bar_form').trigger('submit', {id: $(this).attr('id')});
    });

    // initialize hiring date pickers
    doulaeasy.init_hiring_date_pickers();

    // initialize job count slider
    doulaeasy.init_job_count_slider();

    // initialize hourly salary slider
    doulaeasy.init_hourly_salary_slider();

    // initialize more search filters for mobile
    if ($.browser.mobile && doulaeasy.show_mobile_version) {
        
        $('#more_search_filters').slideUp(); // 20150601: workaround fix for mobile animation
        $('div.more-search-filters').attr('style',"display:none!important"); // 20150601: workaround fix for mobile animation

        var search_bar_slide_up = function() {
            $('#more_search_filters').slideUp({
                complete: function() {
                    $('div.more-search-filters').attr('style',"display:none!important"); // 20150601: workaround fix for mobile animation
                }
            });
            $('.double.angle').removeClass('up');
            $('.double.angle').addClass('down');
        }
        var search_bar_slide_down = function() {
                $('.double.angle').removeClass('down');
                $('.double.angle').addClass('up');
            // }
            $('div.more-search-filters').attr('style',"");  // 20150601: workaround fix for mobile animation
            $('#more_search_filters').slideDown();
        }
        $('#more_search_filters_button').click(function(){
            if ($('#more_search_filters').is(":visible")) {
                search_bar_slide_up();
            }
            else {
                search_bar_slide_down();
            }
        });
    }

    doulaeasy.search_bar_scroll_fix_handler = function() {
        if ($('#search_bar').hasClass('scroll-fixed')) {
            $('div.launch_menu_button').addClass('display_none');
        }
        else {
            $('div.launch_menu_button').removeClass('display_none');
        }
    }

    doulaeasy.update_helper_collection_count();

    if (initialize) {
        $('#search_bar')
          .visibility({
            type   : 'fixed',
            className : {
              fixed     : 'scroll-fixed',
            },
            onUpdate: function() {
                doulaeasy.search_bar_scroll_fix_handler();
            },
            offset : 0 // give some space from top of screen
          })
        ;
    }
 
    // attempt to fix multiple handlers bind to search bar elements
    $('#search_bar_form').unbind().on('change submit', function(e, from) {

        if (doulaeasy.is_guest) {
            
            // show pop up modal
            doulaeasy.show_parent_register_modal();
            return;
        }

        e.preventDefault(); // prevent native submit

        if (!$('#search_role').val() && (typeof from == 'undefined' || from.id != 'helper_collection')) {
            $('#select_doula').click();
        }

        // eject helper collection button if change on other buttons
        if ((typeof from == 'undefined') || (from.id != 'helper_collection')) {
            if ($('input[name="helper_collection"]').val() != "")
                $('#helper_collection').click();
        }

        doulaeasy.scroll_to_search_bar();

        _this = $(this);
        submit_func = function(_this) {

            previous_submit = null; // signal that submit func execution has started

            // clear_animation_queue();
            collapse_search_result();
            t = jQuery.now()+ slidingDuration;

            $('#loading_result').delay(slidingDuration).fadeIn();

            ajax_call_seq++;
            var this_seq = ajax_call_seq;

            var success_func = function(data){

                if (this_seq != ajax_call_seq) return;

                t = t-jQuery.now();
                t = (t >= 0)? t : 0;

                $('#loading_result').fadeOut(2000); // hide the loading icon

                var display_new_result = function() {
                    //call the method to destroy the current infinitescroll session.
                    $('#boxes').infinitescroll('destroy');
                    $('#boxes').data('infinitescroll', null);

                    $('#search_result_segment').html(data);
                    
                    init_search_result(true);
                }
                
                setTimeout(display_new_result, t);
            }

            var req = _this.ajaxSubmit({
                success: success_func
            });
        };
        
        if (previous_submit!=null) {
            clearTimeout(previous_submit);
            previous_submit = null;
        }

        previous_submit = setTimeout("submit_func(_this);",1500);

    });

    $('#reset_form_button').unbind().click(doulaeasy.reset_home_search_form);
    $('#jump_to_top_button').unbind().click(doulaeasy.scroll_to_top);


}

doulaeasy.scroll_to_top = function() {
    $('html, body').animate({
        scrollTop: 0
    }, 1000);
}

doulaeasy.scroll_to_search_bar = function() {
    $('html, body').animate({
        scrollTop: ($('#search_bar_outer').offset().top + 1)
    }, 1000);
}

doulaeasy.reset_home_search_form = function() {
    $('#search_bar_form').replaceWith(doulaeasy.original_search_form);
    init_search_bar(false);
    $('#search_bar_form').submit();
}

doulaeasy.helper_collection_button_handler = function (is_button_active, user_name, helper_id) {

    if (!is_button_active) {
        if ( self !== top ) window.parent.add_helper_to_collection(user_name, helper_id);
        add_helper_to_collection(user_name, helper_id);
    }
    else {
        if ( self !== top ) window.parent.remove_helper_from_collection(user_name, helper_id);
        remove_helper_from_collection(user_name, helper_id);
    }
    return false;
}

var init_helper_collection_button = function(user_name, helper_id) {

    if (window.parent.helper_in_collection(helper_id)) {
        show_helper_collected_label(user_name);
    }

    var helper_collected_label_btn = $('.add_helper_collection_label_btn_'+user_name);

    if (helper_collected_label_btn) {
        helper_collected_label_btn.click(function(event) {
            doulaeasy.helper_collection_button_handler(!$(this).hasClass('disabled'), user_name, helper_id);
            event.preventDefault();
            event.stopPropagation();

        });
        helper_collected_label_btn.popup({
            position: "top center",
            // offset: -100,
            distanceAway: 50,
        });
    }

    

    var helper_collected_btn = $('.add_helper_collection_btn_'+user_name);

    if (helper_collected_btn)
    helper_collected_btn.click(function(event) {
        doulaeasy.helper_collection_button_handler($(this).hasClass('active'), user_name, helper_id);
        event.preventDefault();
        event.stopPropagation();
    });
}

var show_helper_profile = function(user_name) {

    $('#loading_iframe').fadeIn();
    $('#helper_profile_iframe').attr('src', 'helpers/' + user_name + '?profile_layout=blank');  
    $('#helper_profile_modal')
    .modal('setting',  {
        onHide    : function(){
            // track hide helper profile modal
            ga('send', 'event',  'helper profile modal', 'close', user_name);

            $('#helper_profile_iframe').attr('src','');
            // $('html').css('overflow','auto');
        },
        transition: 'horizontal flip'
      })
    .modal('show');

    // track show helper profile modal
    ga('send', 'event',  'helper profile modal', 'show', user_name);

    add_helper_to_visited(user_name);
    // $('html').css('overflow','hidden');
}


doulaeasy.determine_flip_or_swap = function() {

    // determine whether card should flip or swap between front and back
    if (doulaeasy.iOS) {
        $('.flip-container-placeholder')
            .find('.back.side').addClass('display_none');
        $('.flip-container-placeholder')
            .removeClass('flip-container-placeholder');
    }
    else {
        $('.flip-container-placeholder')
            .removeClass('flip-container-placeholder').addClass('flip-container')
    }
}

var init_helper_card = function(user_name, helper_id) {

    var helper_card = $('#'+user_name);

    if ($.browser.mobile) { // if mobile

        // helper_card.find('.click-enter-text').html(doulaeasy.click_here_enter);
        
        helper_card.find('.click-enter').swipe( {
            tap:function(event, direction, distance, duration, fingerCount, fingerData) {

                // track click helper card
                ga('send', 'event',  'helper card', 'click', user_name);

                event.preventDefault();
                event.stopPropagation();
                show_helper_profile(user_name);
            }
        });

        // helper_card.find('.helper-phone').wrap('<a href="tel:+852 '+ $('#helper-phone-'+user_name).val() +'" onclick="ga("send", "event",  "phone number", "click", "'+ user_name +'");"><a>');
        // helper_card.find('.helper-email').wrap('<a href="mailto:'+ $('#helper-email-'+user_name).val() +'" onclick="ga("send", "event",  "email", "click", "'+ user_name +'");"><a>');
        // helper_card.find('.bottom-instruction').html(doulaeasy.helper_card_swipe_me_flip_mobile);

    }
    else { // if desktop

        helper_card.click(function() {

            // track click helper card
            ga('send', 'event',  'helper card', 'click', user_name);

            show_helper_profile(user_name);
        });

    }

    animate_helper_card(user_name);
    init_helper_collection_button(user_name, helper_id);


}
