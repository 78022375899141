
if ($.cookie('helper_visited') == null || $.cookie('helper_visited') == '') {
	helpers_visited_array = [];
}
else {
	helpers_visited_array = $.cookie('helper_visited').split(',');
}


function add_helper_to_visited(user_name) {
	user_name = user_name.toString();
	if ($.inArray(user_name,helpers_visited_array) == -1) {
		helpers_visited_array.push(user_name);
	}
	show_helper_visited_label(user_name);
	store_helper_visited_cookie();
}

function store_helper_visited_cookie() {
	$.cookie('helper_visited', helpers_visited_array.join(), {domain: site_domain, expires:365});
}

function clear_helper_visited() {
	helpers_visited_array = [];
	$.cookie('helper_visited', '', {domain: site_domain, expires:365});
}

function show_helper_visited_label(user_name) {
	var helper_visited = $('.helper_visited_' + user_name);
	if (helper_visited.length > 0) {
		helper_visited.fadeIn();
	}
}

function init_helper_visited() {
	// show label for visited helpers
   	$.each( helpers_visited_array, function( index, value ){
		show_helper_visited_label(value);
	});	
}

$(function() {
	init_helper_visited();
});