
	// initialize wished helper list
	if (!doulaeasy.wished_helper_list == null || !doulaeasy.wished_helper_list == '') {
		doulaeasy.wished_helpers = doulaeasy.wished_helper_list.split(',');
	}
	else if (!$.cookie(doulaeasy.cookie_helper_collection) == null || !$.cookie(doulaeasy.cookie_helper_collection) == '') {
		doulaeasy.wished_helpers = $.cookie(doulaeasy.cookie_helper_collection).split(',');
	}
	else {
		doulaeasy.wished_helpers = [];
	}

function helper_in_collection(helper_id) {
	var helper_id = helper_id.toString();
	
	return $.inArray(helper_id,doulaeasy.wished_helpers) >= 0;
}

function add_helper_to_collection(user_name, helper_id) {
	var user_name = user_name.toString();
	var helper_id = helper_id.toString();

	if ($.inArray(helper_id,doulaeasy.wished_helpers) == -1) {

	    // track add helper to collection
	    ga('send', 'event',  'helper collection', 'add', helper_id);

		doulaeasy.wished_helpers.push(helper_id);
		doulaeasy.wished_helpers = jQuery.grep(doulaeasy.wished_helpers, function(value) {
		  return (value != user_name);
		}); // to be deprecated, added to clean past user_name data in client's cookie
	}
	
	show_helper_collected_label(user_name);

	store_helper_collection();
}

function remove_helper_from_collection(user_name, helper_id) {
	var user_name = user_name.toString();
	var helper_id = helper_id.toString();

    // track add helper to collection
    ga('send', 'event',  'helper collection', 'remove', user_name);

	doulaeasy.wished_helpers = jQuery.grep(doulaeasy.wished_helpers, function(value) {
	  return ((value != helper_id) && (value != user_name));
	});

	hide_helper_collected_label(user_name);

	store_helper_collection();
}

function store_helper_collection() {

	// deduplicate helper collection array
	jQuery.unique(doulaeasy.wished_helpers);

	doulaeasy.update_helper_collection_count();
	
	// store in client side
	$.cookie(doulaeasy.cookie_helper_collection, doulaeasy.wished_helpers.join(), {domain: site_domain, expires:365, path: '/'});

	// store in server side
	if (doulaeasy.wished_helper_list_save_path) {
		$.ajax({
			method: 'POST',
			url: doulaeasy.wished_helper_list_save_path,
			data: {
				wished_helper_list: doulaeasy.wished_helpers.join(),
				wished_helper_list_parent_id: doulaeasy.wished_helper_list_parent_id,
			},
			// dataType: 'script',
			// success: function () {
			// 	console.log('store helper collection success');
			// }
		});
	}
}

function clear_helper_collection() {
	doulaeasy.wished_helpers = [];
	
	store_helper_collection();
}

function show_helper_collected_label(user_name) {
	var helper_collected_label = $('.helper_collected_' + user_name);
	if (helper_collected_label.length > 0) {
		helper_collected_label.fadeIn();
	}

	// make helper collection button: active
	if ($('.add_helper_collection_btn_'+user_name).length > 0)
		$('.add_helper_collection_btn_'+user_name).addClass('active');

	// make helper collection label button: active
	if ($('.add_helper_collection_label_btn_'+user_name).length > 0)
		$('.add_helper_collection_label_btn_'+user_name).removeClass('disabled');

}

function hide_helper_collected_label(user_name) {

	var helper_collected_label = $('.helper_collected_' + user_name);
	if (helper_collected_label.length > 0) {
		helper_collected_label.fadeOut();
	}

	// make helper collection button: disabled
	if ($('.add_helper_collection_btn_'+user_name).length > 0)
		$('.add_helper_collection_btn_'+user_name).removeClass('active');

	// make helper collection label button: active
	if ($('.add_helper_collection_label_btn_'+user_name).length > 0)
		$('.add_helper_collection_label_btn_'+user_name).addClass('disabled');

}

doulaeasy.update_helper_collection_count = function() {

    // reflect helper collection count in front-end
    if ($('.helper-collection-count')) {
        if (doulaeasy.wished_helpers.length > 0)
            $('.helper-collection-count').html('('+doulaeasy.wished_helpers.length+')');
        else
            $('.helper-collection-count').html('');
    }
}


function init_helper_collected() {

	// only keep numeric values in the helper collection list
	doulaeasy.wished_helpers = jQuery.grep(doulaeasy.wished_helpers, function(value) {
	  return jQuery.isNumeric(value);
	}); 

	// show label for collected helpers
   	$.each( doulaeasy.wished_helpers, function( index, value ){
		show_helper_collected_label(value);
	});

}

$(function() {
	init_helper_collected();
});