
doulaeasy.ajax_check_email_verified_ajax_started = false;
doulaeasy.ajax_check_email_verified_callbacks = [];

doulaeasy.ajax_check_email_verified = function() {

	doulaeasy.ajax_check_email_verified_ajax_started = true;

	$.ajax({
		url: doulaeasy.ajax_check_email_verified_link,
		method: 'GET',
		success: function(data) {
			if (data == false) {
				setTimeout(doulaeasy.ajax_check_email_verified, 4000);
			}
			else {
				// execute all callback functions
				$( doulaeasy.ajax_check_email_verified_callbacks ).each(function(index, func) {
					func();
				});
			}
		}
	});
}

doulaeasy.resend_email_confirmation_callbacks = [];
doulaeasy.resend_email_confirmation = function(user_id) {
	$.ajax({
		url : doulaeasy.ajax_resend_email_confirmation_link,
		type: 'GET',
		data: {
			user_id: user_id
		},
		success: function(data){
			toastr.success(doulaeasy.lang_resend_email_confirmation);

			// execute all callback functions
			$( doulaeasy.resend_email_confirmation_callbacks ).each(function(index, func) {
				func();
			});
		}
    });
    toastr.info(doulaeasy.lang_sending_email_confirmation);

}