// update ckeditor to textarea
function CKupdate(){
    for ( instance in CKEDITOR.instances )
        CKEDITOR.instances[instance].updateElement();
}

$(function() {
	// update textarea values before ajax call
	$('form').on('ajax:before', function(event, xhr, settings) {
	  	if (typeof CKEDITOR !== 'undefined') CKupdate();
	});	
});
