doulaeasy.init_date_picker = function(date_selector, config) {
  var date = $( date_selector );

  date.datepicker(config);

    $(function() {
        // set default dates
        if (date.val()) {
            date.datepicker('setDate',new Date( date.val() ) );
        }
    });
}


doulaeasy.init_date_range_pickers = function(from_date_selector, to_date_selector) {

	// these elements can be found in home search bar
	var from_date = $( from_date_selector );
	var to_date = $( to_date_selector );

	// if both elements are found, prepare the elements

	from_date.datepicker({
      changeMonth: true,
      changeYear: true,
      onClose: function( selectedDate ) {
      	if (selectedDate) {
        	$( to_date_selector ).datepicker( "option", "minDate", selectedDate );
        }
      },
    });
	to_date.datepicker({
      changeMonth: true,
      changeYear: true,
      onClose: function( selectedDate ) {
      	if (selectedDate) {
        	$( from_date_selector ).datepicker( "option", "maxDate", selectedDate );
        }
      },
    });

    $(function() {
        from_date = $( from_date_selector );
        to_date = $( to_date_selector );
        // set default dates
        if (to_date.val()) {
            var to_date_value = new Date(to_date.val());
            to_date.datepicker('setDate', to_date_value);
        }
        if (from_date.val()) {
            var from_date_value = new Date( from_date.val());
            from_date.datepicker('setDate', from_date_value);
            to_date.datepicker( "option", "minDate", from_date_value);
        }
        if (to_date.val()) {
            from_date.datepicker( "option", "maxDate", to_date_value );
        }
    });

};