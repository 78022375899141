doulaeasy.datatables = {};

doulaeasy.initialize_columns_visibility = function(table_name) {
	// initialize datatable for the table
	doulaeasy.datatables[table_name] = {};

	var current_datatable = $('table.'+ table_name).DataTable( {
        "paging": false,
        "ordering": false,
        "info": false,
        "searching": false,
        "language": {
            "zeroRecords": doulaeasy.lang_no_records,
            // "lengthMenu": "Display _MENU_ records per page",
            // "info": "Showing page _PAGE_ of _PAGES_",
            // "infoFiltered": "(filtered from _MAX_ total records)"
            // "infoEmpty": "No records available",
        }
    } );
    doulaeasy.datatables[table_name]['api'] = current_datatable;

	// initialize columns visibility according to cookie values
	if ($.cookie('column_visibility_'+table_name) == null || $.cookie('column_visibility_'+table_name) == '') {
	}
	else {
		var column_selection_array = $.cookie('column_visibility_'+table_name).split(',');

		jQuery.each( column_selection_array, function(key, val) {
			if (key < current_datatable.columns()[0].length && current_datatable.column(key) !== undefined)
   				current_datatable.column(key).visible(val === 'true');
		} );
	}
	doulaeasy.update_hidden_column_section(table_name);

	// register click listener
	$('table.'+ table_name + ' >> tr.column-fields').on('click','th', function() {
		doulaeasy.toggle_column_visibility(table_name, current_datatable.column( this ).index());
	});

}
doulaeasy.toggle_column_visibility = function(table_name, column_index) {
	//  use datatable api to toggle the column to hidden/visible
	var current_datatable = doulaeasy.datatables[table_name]['api'];
	var column = current_datatable.column(column_index);

	if (column !== undefined)
    	column.visible( ! column.visible() );

    // store the visibility result to cookie
    doulaeasy.store_columns_visibility(table_name);

    // update the hidden columns section
    doulaeasy.update_hidden_column_section(table_name);
}

// compile a list of hidden columns	
doulaeasy.compile_hidden_columns_list = function(table_name) {

	var hidden_columns_list = {};
	var current_datatable = doulaeasy.datatables[table_name]['api'];

	// find the hidden columns: (key, column_name) pairs array
	jQuery.each(current_datatable.columns().visible(), function(key, val) {
		if (key != null && val === false) {
			hidden_columns_list[key] = $(current_datatable.column(key).header()).html();
		}
	});

	// store in datatables variable
	current_datatable['hidden_columns'] = hidden_columns_list;

	return hidden_columns_list;
}

doulaeasy.update_hidden_column_section = function(table_name) {
	// fetch hidden columns
	var hidden_columns_list = doulaeasy.compile_hidden_columns_list(table_name);

	// update the display of the section
	var label_tag = '<a class="ui label" data-index="%1">%2</a>';
  	var result_html = '';
	jQuery.each(hidden_columns_list, function(key, val) {
		result_html += label_tag.replace('%1',key).replace('%2',val);
	});

	// modify hidden columns section html
	var hidden_label_div = $('table.'+table_name).find('div.hidden-columns');
	hidden_label_div.html(result_html);

	// re-register click listeners
	hidden_label_div.unbind('click').on('click','.ui.label',function() {
		console.log('index:'+$(this).data('index')); //TODO: debug
		doulaeasy.toggle_column_visibility(table_name, $(this).data('index'));
	});

}
doulaeasy.store_columns_visibility = function(table_name) {
	// fetch the datatable visible array
	var current_datatable = doulaeasy.datatables[table_name]['api'];
	var visibile_columns_array = current_datatable.columns().visible();

	// update the whole array into cookie	
	$.cookie('column_visibility_'+table_name, visibile_columns_array.join(), 
		{domain: site_domain, expires:365, path: '/'});
}