
doulaeasy.helper_sortable_zone = '.sortable-list';

// initialize sortable lists
doulaeasy.initialize_sortable_zone = function() {
	$(doulaeasy.helper_sortable_zone).sortable({
	  items: '.ui.card',
      revert: true,
      connectWith: doulaeasy.helper_sortable_zone,
      update: function(event, ui) {
			// update wished helper list n front-end and back-end if the list is updated
			if ($(event.target).parents("#wished_helpers").length == 1) {
				setTimeout(doulaeasy.update_wished_helpers_variable, 200);
			}
			doulaeasy.update_helper_item_card_number();

	  },
      remove: function(event,ui) {
		$(event.target).sortable('cancel');
      },

      receive: function(event,ui) {

  			clone_card = $('div[data-id='+ ui.item.data('id')+ ']').eq(0).clone()
  				.attr('style','').removeClass('ui-sortable-helper');

  			if (!doulaeasy.is_admin) {
  				if ($(event.target).parents("#interested_helpers").length == 1)
  					return;
  			}

	  		$(event.target).append(clone_card);			  		
			doulaeasy.deduplicate_list(event.target);

      },
    });
};

doulaeasy.deduplicate_list = function(list) {
	var card_freq = [];
	$.each($(list).find('> .ui.card'), function(key, value) { 
		if (card_freq[$(value).data('id')] == 1) {
			value.remove();
		}
		else {
			card_freq[$(value).data('id')] = 1;
		}
	});
}

doulaeasy.remove_helper_item = function(item) {
	if (confirm(doulaeasy.lang_confirm_delete_q)) {
		item.closest('.ui.card').remove();
		doulaeasy.update_wished_helpers_variable(); // update wished list
	}
}

doulaeasy.edit_helper_item = function(item) {

	var helper_cards_zone_id = $(item).closest('.ui.segment.hiring-item-board').attr('id');
	var helper_card = $(item).closest('.ui.card');

	$('#helper_item_modal_' + helper_cards_zone_id)
	  .modal({
	    onShow: function() {
	    	doulaeasy.update_helper_item_modal_from_card(this, helper_cards_zone_id, helper_card);
	    },
	    onHide: function() {
	    	doulaeasy.update_helper_item_card_from_modal(this, helper_cards_zone_id, helper_card);
	    	doulaeasy.update_helper_item_card_labels(helper_card);
	    }
	  })
	  .modal('show')
	;
}

doulaeasy.update_helper_item_card_number = function() {

	// update help items numbering
	$.each($('div.helper-item-zone'), function(key,val) {
	  $.each($(val).find('.helper-item'), function(k,v) {
	    $(v).find('span.number').html(k+1);
	  });
	});
}

doulaeasy.update_helper_item_modal_from_card = function(modal, helper_cards_zone_id, card) {

	// add helper card zone class to modal
	$(modal).addClass(helper_cards_zone_id);

	// foreach input of card
	$.each(card.find('input'), function(key,value) {

		var modal_input = $(modal).find('input[name='+value.name+']');

		// if modal input is found which corresponds to card's input
		if (modal_input != null) {
			if ($(modal_input).attr('type') == 'checkbox') {
				$(modal_input).prop('checked', ($(value).val() == '1'));
			}
			else if ($(modal_input).attr('type') == 'radio') {
				$(modal_input).filter(function() { return this.value == $(value).val(); }).click();
			}
			else {
				$(modal_input).val(value.value);
			}
		}
	});
	$.each(card.find('textarea'), function(key,value) {
		var modal_input = $(modal).find('textarea[name='+value.name+']');

		// if modal textarea is found which corresponds to card's textarea
		if (modal_input != null) {
			$(modal_input).val(value.value);
		}
	});


}

doulaeasy.update_helper_item_card_from_modal = function(modal, helper_cards_zone_id, card) {
	
	// remove helper card zone class from modal
	$(modal).removeClass(helper_cards_zone_id);

	// foreach input of modal
	$.each(card.find('input'), function(key,value) {

		var modal_input = $(modal).find('input[name='+value.name+']');

		// if modal input is found which corresponds to card's input
		if (modal_input != null) {
			if ($(modal_input).attr('type') == 'checkbox') {
				$(value).val( $(modal_input).prop('checked') ? '1' : '0');
			}
			else if ($(modal_input).attr('type') == 'radio') {
				$(value).val($(modal_input).filter(':checked').val());
			}
			else {
				$(value).val(modal_input.val());
			}
		}

	});
	$.each(card.find('textarea'), function(key,value) {

		var modal_input = $(modal).find('textarea[name='+value.name+']');

		// if modal textarea is found which corresponds to card's textarea
		if (modal_input != null) {
			$(value).val(modal_input.val());
		}
	});

}

doulaeasy.update_helper_item_card_labels = function(card) {
	
	// foreach input of card
	$.each($(card).find('input'), function(key,value) {

		var field_div = $(card).find('div[data-id='+value.name+']');
		var label = $(card).find('label[for='+value.name+']');

		if ($(field_div).hasClass('optional')) {
			if (value.value == '' || value.value == 0)
				$(field_div).hide();
			else 
				$(field_div).show();
		}
		
		if ($(label).hasClass('boolean')) {

			// console.log(value.value);

			if (value.value == '1') {
				$(label).filter('.true').show();
				$(label).filter('.false').hide();
			}
			else if (value.value == '0') {
				$(label).filter('.true').hide();
				$(label).filter('.false').show();
			}
			else {
				$(label).filter('.true').hide();
				$(label).filter('.false').hide();
			}
		}
		else {
			if (value.value == '') {
				label.html(doulaeasy.lang_na);
			}
			else {
				label.html(value.value);
			}
		}

	});
	$.each($(card).find('textarea'), function(key,value) {

		var field_div = $(card).find('div[data-id='+value.name+']');
		var label = $(card).find('label[for='+value.name+']');

		if ($(field_div).hasClass('optional')) {
			if (value.value == '')
				$(field_div).hide();
			else 
				$(field_div).show();
		}

		label.html(value.value);
	});

}

doulaeasy.update_wished_helpers_variable = function() {

	// update wished helper list n front-end and back-end if the list is updated
	var wished_helpers = $('#wished_helpers > .sortable-list').sortable('toArray',{ attribute: 'data-id'} );
	doulaeasy.wished_helpers = wished_helpers;
	store_helper_collection();
}


doulaeasy.compose_case_related_helpers_input = function() {
	$.each(doulaeasy.sortable_zones, function(key, value) {
		var sortable = $('#'+value).find('.sortable-list');

		if (sortable.hasClass('sortable-list')) {
			var helpers_array = doulaeasy.create_array_from_sortable(sortable);
			$('input[name='+value+']').val(helpers_array);
		}
	});

	// return true;
}

doulaeasy.create_array_from_sortable = function(sortable) {
	var helpers_array = {};
	var helper_id_list = sortable.sortable('toArray',{ attribute: 'data-id'} );

	$.each(helper_id_list, function(key, value) {
		var helper_array = {};
		var helper_item = sortable.find('div[data-id='+value+'].ui.card');
		helper_array['order'] = key;
		
		$.each(helper_item.find('input'), function(key,value) {
			helper_array[value.name] = value.value;
		});
		$.each(helper_item.find('textarea'), function(key,value) {
			helper_array[value.name] = value.value;
		});


		helpers_array[value] = helper_array;
	});

	return JSON.stringify(helpers_array);
}

doulaeasy.initialize_datetimepicker_inputs = function() {
		$('input.datetimepicker').datetimepicker({
	  format:'Y-m-d H:i',
	  step: 30,
	});
	$('input.datepicker').datepicker();
	};

doulaeasy.initialize_all_helper_items = function() {
	$.each( $('.ui.helper-item.card'), function(key, card) {
	doulaeasy.update_helper_item_card_labels(card);
	});
	doulaeasy.update_helper_item_card_number();
}