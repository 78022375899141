doulaeasy.init_job_count_slider = function(init_from_input, onchange_callback) {

    var change_callback = function(event, ui) {
        // track select requested hourly salary
        ga('send', 'event',  'home search: requested min job count', 'selected', 'amount', $('#requested_min_job_count').val());
        ga('send', 'event',  'home search: requested max job count', 'selected', 'amount', $('#requested_max_job_count').val());

        $('#search_bar_form').submit();
    }

  var update_job_count_text = function(min, max) {
    var min_smaller = (min == doulaeasy.min_job_count ) ? '' : '';
    var max_bigger = (max == doulaeasy.max_job_count ) ? doulaeasy.job_count_text['bigger_than'] : '';
    var new_range = doulaeasy.job_count_text['need_past_experience'] + min + min_smaller + " " + doulaeasy.job_count_text['times'] + " " + doulaeasy.job_count_text['to']  + max +  doulaeasy.job_count_text['times'] + max_bigger;
    $("#job_count_range" ).html( new_range );
    $('#requested_min_job_count').val(min);
    $('#requested_max_job_count').val(max);
  }

  $(function() {
    var job_count_slider = $( "#slider-range-job-count" );

    if (onchange_callback) {
        change_callback = onchange_callback;
    }

    job_count_slider.slider({
      range: true,
      min: doulaeasy.min_job_count,
      max: doulaeasy.max_job_count,
      //values: [ doulaeasy.init_lowest_job_count, doulaeasy.init_highest_job_count ],
      slide: function( event, ui ) {
        var min = ui.values[ 0 ];
        var max = ui.values[ 1 ];
        update_job_count_text(min, max);
      },
      change: function(event, ui) {
          change_callback(event, ui);
      },
    });
    var initMin = job_count_slider.slider( "values", 0 );
    var initMax = job_count_slider.slider( "values", 1 );
    var min = $('#requested_min_job_count').val();
    var max = $('#requested_max_job_count').val();
    var isSelected = false;

    if (min != '' && max != '') {
      isSelected = true;
    }

    if (!isSelected) {
      initMin = '-';
      initMax = '-';
    }
    var min_smaller = (initMin == doulaeasy.min_job_count ) ? '' : '';
    var max_bigger = (initMax == doulaeasy.max_job_count ) ? doulaeasy.job_count_text['bigger_than'] : '';
    var new_range = doulaeasy.job_count_text['need_past_experience'] + initMin + min_smaller + " " + doulaeasy.job_count_text['times'] + " " + doulaeasy.job_count_text['to']  + initMax + " " + doulaeasy.job_count_text['times'] + max_bigger;
    $("#job_count_range" ).html( new_range);

    if (isSelected) {
      job_count_slider.slider({
        values: [ min, max ]
      });
      update_job_count_text(min, max);
    }
  });
}

doulaeasy.init_hourly_salary_slider = function(init_from_input, onchange_callback) {

    var change_callback = function() {
        // track select requested hourly salary
        ga('send', 'event',  'home search: requested min hourly salary', 'selected', 'amount', $('#requested_min_hourly_salary').val());
        ga('send', 'event',  'home search: requested max hourly salary', 'selected', 'amount', $('#requested_max_hourly_salary').val());

        $('#search_bar_form').submit();
    }

  var update_hourly_salary_text = function(min, max) {
    var min_smaller = (min == doulaeasy.min_hourly_salary ) ? '' : '';
    var max_bigger = (max == doulaeasy.max_hourly_salary ) ? doulaeasy.hourly_salary_text['bigger_than'] : '';
    var new_range = doulaeasy.hourly_salary_text['willing_to_pay_hourly_salary'] + min + min_smaller + " " + doulaeasy.hourly_salary_text['to'] + " $" + max + max_bigger;
    $("#hourly_salary_range" ).html( new_range );
    $('#requested_min_hourly_salary').val(min);
    $('#requested_max_hourly_salary').val(max);
  };

  $(function() {
    var hourly_salary_slider = $( "#slider-range-hourly-salary" );

    if (onchange_callback) {
        change_callback = onchange_callback;
    }

    hourly_salary_slider.slider({
      range: true,
      min: doulaeasy.min_hourly_salary,
      max: doulaeasy.max_hourly_salary,
      //values: [ doulaeasy.init_lowest_hourly_salary, doulaeasy.init_highest_hourly_salary ],
      slide: function( event, ui ) {
        var min = ui.values[ 0 ];
        var max = ui.values[ 1 ];

        update_hourly_salary_text(min, max);
      },
      change: change_callback,
    });
    var initMin = hourly_salary_slider.slider( "values", 0 );
    var initMax = hourly_salary_slider.slider( "values", 1 );
    var min = $('#requested_min_hourly_salary').val();
    var max = $('#requested_max_hourly_salary').val();
    var isSelected = false;

    if (min != '' && max != '') {
      isSelected = true;
    }

    if (!isSelected) {
      initMin = '-';
      initMax = '-';
    }

    var min_smaller = (initMin == doulaeasy.min_hourly_salary ) ? '' : '';
    var max_bigger = (initMax == doulaeasy.max_hourly_salary ) ? doulaeasy.hourly_salary_text['bigger_than'] : '';
    var new_range = doulaeasy.hourly_salary_text['willing_to_pay_hourly_salary'] + initMin + min_smaller + " " + doulaeasy.hourly_salary_text['to'] + " $" + initMax + max_bigger;
    $("#hourly_salary_range" ).html(new_range);

    if (isSelected) {
      hourly_salary_slider.slider({
        values: [ min, max ]
      });
      update_hourly_salary_text(min, max);
    }
  });
}


