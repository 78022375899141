
var resizePpt = function() {
  $('.powerpoint-iframe').height($('.powerpoint-iframe').width()/5.1*4);
}

$(function() {
	if ($('.powerpoint-iframe')) {
		resizePpt();
		$( window ).resize(resizePpt);
	}
});