doulaeasy.getParameterByName = function (name, url) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(url);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// made for list view items such as featured list and archived post list
doulaeasy.itemClickHandler = function(e, _this) {
		if( e.which == 2 ) {
	      	e.preventDefault();
	        var win = window.parent.open($(_this).find("a.item-link").attr("href"), '_blank');
				win.focus();
	      return false;
		}
		window.parent.location = $(_this).find("a.item-link").attr("href"); 
};

// made to format number with commas
doulaeasy.numberWithCommas = function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

doulaeasy.formatAndRoundNumbers = function(value, round_to_number) {
  if (round_to_number == null) {
    round_to_number = doulaeasy.monthly_salary_round_to_number
  }
  return doulaeasy.numberWithCommas(
    Math.round(value/round_to_number)*round_to_number
  );
}

// delay function
doulaeasy.delay = (function(){
  var timer = 0;
  return function(callback, ms){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();
