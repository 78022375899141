$(function() {
	$('.ui.modal')
	  .modal()
	;

	// $('body').removeClass('dimmable'); // 20150529: fix for semantic-ui 1.12: jump to top when modal show
});


// 20191213 Attempt to fix touch issue for scrolling
// ref: https://github.com/Semantic-Org/Semantic-UI/issues/6449
EventTarget.prototype['addEventListenerBase'] = EventTarget.prototype.addEventListener;
EventTarget.prototype.addEventListener = function(type, listener){
	if(this !== document.querySelector('body') || type !== 'touchmove'){
		this.addEventListenerBase(type, listener);
	}
};