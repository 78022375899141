$(function() {
	$('.zoom-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		closeOnContentClick: false,
		closeBtnInside: false,
		mainClass: 'mfp-with-zoom mfp-img-mobile',
		image: {
			verticalFit: true,
			titleSrc: function(item) {
				return item.el.attr('title');
			}
		},
		gallery: {
			enabled: true
		},
		zoom: {
			enabled: true,
			duration: 300, // don't foget to change the duration also in CSS
			opener: function(element) {
				return element.find('img');
			}
		}
		
	});

	$('.image-popup-fit-width').magnificPopup({
		type: 'image',
		delegate: 'a',
		closeOnContentClick: true,
		image: {
			verticalFit: false
		}
	});
});

function preload_image(arrayOfImages) {
    $(arrayOfImages).each(function(){
        $('<img />')[0].src = this;
        // Alternatively you could use:
        // (new Image()).src = this;
    });
}