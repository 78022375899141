
// make check exists for semantic.ui rules
$.fn.form.settings.rules.exists = function(val, args) {

	var args = args.split('|');
	if (args.length < 3) 
		return;
	var field_id = args[0];
	var check_url = args[1];
	var prompt_message = args[2];

	var result = $.ajax({
		url: check_url,
		data: { value: val },
		method: 'get',
		success: function(response) {
			if (response == doulaeasy.YES)
				$('#register_form').form('add prompt', field_id, prompt_message);
			// else if ()
				// $('#register_form').form('remove prompt', {identifier: field_id});
		}
	});
	return true;
};


// // make check selected for semantic.ui rules
// $.fn.form.settings.rules.selected = function(val, args) {

// 	var args = args.split('|');
// 	if (args.length < 2) 
// 		return;
// 	var field_id = args[0];
// 	// var check_url = args[1];
// 	var prompt_message = args[1];

// 	console.log($('input[name='+field_id+']:checked').val());

// 	if ($('input[name='+field_id+']:checked').val() == null) {
// 		setTimeout(function() {
// 			console.log('callback:field_id['+field_id+'], prompt_message['+prompt_message+']');

// 			field_id = $('input[name='+field_id+']')[0].id;

// 			$('#register_form').form('add prompt', field_id, prompt_message);
// 		}, 500);
// 	}
// 	else {
// 		$('#register_form').form('remove prompt', {identifier: field_id});
// 	}

// 	return true;

// };

